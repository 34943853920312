:root {
    --primary-c-dark: #395144;
    --primary-c-light: #4E6C50;
    --secondary-c-light: #F0EBCE;
    --secondary-c-dark: #AA8B56;
    --selection-c: #009300;
}

ul.navbar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: var(--secondary-c-dark);
    border-radius: 5px;

}

.leftNav {
    float: left;
}

.rightNav {
    float: right;
}

.nav-link {
    display: block;
    color: var(--secondary-c-light);
    font-weight: bold;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.nav-link:hover {
    background-color: var(--primary-c-light);
}