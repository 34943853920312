:root {
    --primary-c-dark: #395144;
    --primary-c-light: #4E6C50;
    --secondary-c-light: #F0EBCE;
    --secondary-c-dark: #AA8B56;
    --selection-c: #009300;
}

a {
    color: var(--secondary-c-dark);
}



body {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: var(--primary-c-dark);
}

/* width */
::-webkit-scrollbar {
    width: 5px;

}

::-webkit-scrollbar-corner {
    background-color: #00000000;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #4e6c50bd;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--secondary-c-dark);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-c-light);
}

h1 {
    color: var(--secondary-c-light);
    text-align: center;
    padding: 20px 0;
}



.login-entry {
    display: flex;
    /* center children horizontally */
    justify-content: center;
    /* center children vertically */
    align-items: center;
}

.main {
    background-color: var(--primary-c-light);
    width: 400px;
    height: 400px;
    margin: 7em auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
    padding-top: 40px;
    color: var(--secondary-c-light);
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
}

.un {
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: var(--secondary-c-light);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}

form.form1 {
    padding-top: 40px;
}

.pass {
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: var(--secondary-c-light);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}


.un:focus,
.pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;

}

.submit {
    cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background: var(--secondary-c-dark);
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Ubuntu', sans-serif;
    margin-left: 35%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: var(--secondary-c-dark);
    padding-top: 15px;
}

a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: var(--secondary-c-dark);
    text-decoration: none
}

.search-container {
    display: grid;
    grid-template-columns: auto 130px;
    gap: 10px;
    background-color: var(--primary-c-light);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

@media (max-width: 500px) {
    .search-container {
        grid-template-columns: auto 100px;
    }
}

.search-text {
    padding: 10px;
    font-size: 18px;
    width: 100%;
    background-color: var(--secondary-c-light);
    color: black;
    border: none;
    border-color: var(--secondary-c-dark);
    border-radius: 5px;
    cursor: pointer;
    min-width: 0;
    /* Added to prevent the input field from pushing the button outside */
    overflow: scroll;
    /* To make the text inside the input field scrollable */
}

.search-text:focus {
    border-color: var(--secondary-c-dark);
    border-width: 2px;
}

.search-submit {
    padding: 10px 30px;
    font-size: 18px;
    background-color: var(--secondary-c-dark);
    color: var(--secondary-c-light);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    z-index: 2;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.search-submit:hover {
    transform: scale(1.05);
}

/* For smaller devices (phones) */
@media (max-width: 480px) {
    .search-text {
        font-size: 16px;
    }

    .search-submit {
        padding: 5px 10px;
        font-size: 16px;
    }
}




.search-text-container {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0px;

}

.autofill-container {
    position: absolute;
    border: 1px solid var(--secondary-c-dark);
    border-bottom: none;
    border-radius: 5px;
    border-top: none;
    z-index: 98;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 0;
    overflow: auto;
}

.autofill-item {
    padding: 10px;
    cursor: pointer;
    background-color: var(--secondary-c-light);
    border-bottom: 1px solid #d4d4d4;

}

.autofill-item:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}

.search-results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
    grid-auto-flow: row;
    grid-area: auto;
}

.search-results-grid-item {
    border: 0px solid #ccc;
    padding: 13px;
    border-radius: 5px;
    background-color: var(--primary-c-light);
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    transition: transform 0.3s;
}

.search-results-grid-item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.search-results-grid-item img {
    width: 100%;
    height: auto;
    border: 3px solid var(--secondary-c-dark);
    border-radius: 7px;
}

.search-results-grid-item h2 {
    color: var(--secondary-c-light);
}

.filters-results-container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    gap: 20px;
}

.filter-side-bar {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    background-color: var(--primary-c-light);
    padding: 20px;
    padding-top: 0;
    border-radius: 5px;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    height: fit-content;
    width: fit-content;
}



/* For medium devices (tablets) */
@media (max-width: 768px) {
    .filters-results-container {
        grid-template-columns: 1fr;
    }

    .search-results-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

/* For small devices (phones) */
@media (max-width: 480px) {
    .filters-results-container {
        grid-template-columns: 1fr;
    }

    .search-results-grid {
        grid-template-columns: 1fr;
    }
}

.result-navigation {
    margin-top: 20px;
    background-color: var(--primary-c-light);
    padding: 10px;
    border-radius: 5px;
    color: var(--secondary-c-light);
    font-weight: bold;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.result-navigation button {
    border: none;
    padding: 10px;
    font-weight: bolder;
    background-color: var(--secondary-c-dark);
    color: var(--secondary-c-light);
    transition: 0.3s;
    border-radius: 5px;
}

.result-navigation button:hover {
    cursor: pointer;
    background-color: var(--secondary-c-light);
    color: var(--secondary-c-dark);
}

.collapse-trigger {
    display: block;
    margin-top: 20px;
    width: 1fr;
    max-width: 150px;
    min-width: 100px;
    height: 40px;
    background-color: var(--secondary-c-dark);
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: large;
    color: var(--secondary-c-light);
    transition: transform 0.3s;
}

.collapse-trigger:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.filter-contents {
    z-index: 4;
    position: absolute;
}

.visible {
    visibility: visible;
    opacity: 1;

    /* example fixed height */
}

.hidden {
    visibility: collapse;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.filter-form {
    background-color: var(--secondary-c-light);
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border: 2px solid var(--secondary-c-dark);

    transition: visibility 1.2s, opacity 1.2s linear, height 1.2s ease-out;
}

.filter-checkbox {
    padding: 5px;

}

.apply-button {
    width: 100%;
    align-self: center;
}

.recipe-modal {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.recipe-modal-content {
    position: relative;
    z-index: 2;
    background-color: var(--primary-c-light);
    margin: 15% auto;
    padding: 20px;
    border: 10px solid var(--secondary-c-dark);
    border-radius: 5px;
    width: 80%;
    color: var(--secondary-c-light);
}

.m-recipe-options {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.m-recipe-options button {
    font-weight: bold;
    margin-left: 10px;
    padding: 5px 15px;
    border: none;
    background-color: var(--secondary-c-dark);
    color: var(--secondary-c-light);
    text-align: center;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 5px;
}

.m-recipe-options button:hover {
    background-color: var(--secondary-c-light);
    color: var(--secondary-c-dark);
}

.add-plan-list {
    position: absolute;
    /* This will position the dropdown relative to the .m-recipe-options div */
    top: 100%;
    /* Position it just below the .m-recipe-options div */
    left: 0;
    /* Align it with the right side of the .m-recipe-options div */
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: var(--secondary-c-dark);
    /* Give it a background so it doesn't blend with the content underneath */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    /* Optional: give it a bit of shadow for depth */
    border-radius: 5px;
    /* Optional: curve the corners a bit */
    min-width: 200px;
    /* Optional: make sure it's not too narrow */

}

.add-plan-list li {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--secondary-c-light);
    background-color: var(--secondary-c-dark);
}

.add-plan-list li:hover {
    background-color: var(--secondary-c-light);
    color: var(--secondary-c-dark);
}


.m-recipe-image {
    border: 5px solid var(--secondary-c-dark);
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    display: block;
    margin-bottom: 20px;
}

.plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: minmax(0, 1fr);
    /* Adjust the height of the rows as needed */
    gap: 20px;
    grid-auto-flow: row;
    justify-content: center;
    /* Centers the grid horizontally */
    align-content: center;
    /* Centers the grid vertically */
}


.plans-grid-item {
    border: 0px solid #ccc;
    padding: 13px;
    border-radius: 5px;
    background-color: var(--primary-c-light);
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    transition: transform 0.3s;
}

.plans-grid-item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.plans-grid-item img {
    width: 95%;
    height: auto;
    border: 3px solid var(--secondary-c-dark);
    border-radius: 7px;
}

.plans-grid-item h2 {
    color: var(--secondary-c-light);
}

.plans-grid-create-new {
    border: 5px dashed var(--primary-c-light);
    padding: 13px;
    border-radius: 5px;
    background-color: var(--primary-c-dark);
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    transition: transform 0.3s;
}

.plans-grid-create-new:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.plans-grid-create-new h2 {
    align-items: baseline;
    color: var(--secondary-c-light);
}

.recipe-count {
    color: var(--secondary-c-dark)
}

.title-input {
    width: 80%;
    height: 10%;
    background-color: var(--secondary-c-light);
    border: none;
    border-radius: 5px;

}

.title-submit {
    background-color: var(--secondary-c-light);
    font-weight: bolder;
    border: none;
    border-radius: 5px;
}



.warning {
    color: red;
}

.plan-container-grid {
    display: grid;
    grid-template-columns: 1fr;
    /* stack in one column on small screens */
    grid-gap: 0px;
    /* adjust as needed */
}

.plan-container-grid h2 {
    color: var(--secondary-c-light);
}

@media (min-width: 768px) {

    /* adjust breakpoint as needed */
    .plan-container-grid {
        grid-template-columns: 7fr 5fr;
        /* split in two columns on large screens */
    }
}



.ingredients-list {
    background-color: var(--secondary-c-light);
    border: 5px solid var(--secondary-c-dark);
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    color: var(--secondary-c-dark);
    margin-right: 10px;
    max-height: 600px;
    overflow: auto;
}

.ingredient-list-item {
    width: 100%;
    min-width: none;
}

.ingredient-list-item h1 {
    color: var(--secondary-c-dark);
}

.ingredient-list-item h2 {
    color: var(--secondary-c-dark);
}

.recipes-plan-grid {
    padding: 20px;
    padding-top: 0;
    padding-left: 0;
    margin-right: 20px;
}

.recipes-plan-grid-item {
    padding: 10px;
    width: 100%;
    min-width: none;
    color: var(--secondary-c-light);
    background-color: var(--primary-c-light);
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 20px;
    transition: 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.recipes-plan-grid-item:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.recipe-content {
    display: flex;
    align-items: center;
    /* centers the content vertically */
}

.recipe-content img {
    max-width: 150px;
    min-width: none;
    height: auto;
    margin-right: 15px;
    border: 5px solid var(--secondary-c-dark);
    border-radius: 5px;
    /* space between the image and the text */
}

.recipe-content h2 {
    text-align: center;
}

.print-button {
    position: absolute;
    z-index: 2;
}

@media print {
    .no-print {
        display: none;
    }

    .ingredients-list {
        max-height: none;
        height: 100%;
        line-height: 50%;
        margin-top: 0;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .ingredients-list-item h1 {
        font-size: x-small;
    }
}

.red-button {
    background-color: rgb(197, 65, 65) !important;
    color: #e9e9e9 !important;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px;
    transition: 0.3s;
}

.red-button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: red !important;


}

.modal-ordered-list {
    line-height: 30px;
}

.back-button {
    background-color: var(--secondary-c-dark);
    color: var(--secondary-c-light);
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    transition: 0.3s;
}

.back-button:hover {
    background-color: var(--secondary-c-light);
    color: var(--secondary-c-light);
    cursor: pointer;
}

.home-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .home-grid {
        grid-template-columns: 1fr;
    }
}


.home-grid-item {
    border: 7px dashed var(--primary-c-light);
    height: 400px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.home-grid-item h2 {
    text-align: center;
    color: var(--secondary-c-dark);
    font-weight: bold;
}

.home-grid-item p {
    font-weight: bold;
    color: var(--secondary-c-dark);
    bottom: 0;
}

.user-info-card {
    background-color: var(--primary-c-light);
    padding: 20px;
    width: max-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: 5px solid var(--secondary-c-dark);
    border-radius: 5px;
    color: var(--secondary-c-light);
}

.user-info-card img {
    border: 5px solid var(--secondary-c-dark);
    border-radius: 10px;
}

.logout {
    width: 100%;

}