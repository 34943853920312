:root {
    --primary-c-dark: #395144;
    --primary-c-light: #4E6C50;
    --secondary-c-light: #F0EBCE;
    --secondary-c-dark: #AA8B56;
    --selection-c: #009300;
}

.post {
    display: flex;
    /* center children horizontally */
    justify-content: center;
    /* center children vertically */
    align-items: center;
    /* make the container take up the whole screen */
    height: 60vh;
    /* add some gap between buttons */
    gap: 20px;

}

.landmark {

    display: inline-block;
    background-color: var(--primary-c-light);
    color: var(--secondary-c-light);
    border-radius: 5px;
    text-align: center;
    padding: 10px 10px;
    font-size: 1em;
    text-decoration: none;
}

.login-button {
    background-color: var(--secondary-c-dark);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: var(--secondary-c-light);
    font-size: larger;
    font-weight: bold;
}